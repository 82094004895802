<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__riskcheck">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="隐患名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.statusId" placeholder="检查状态" clearable @change="statusSelectChange">
            <el-option label="无需整改" :value="0"></el-option>
            <el-option label="整改中" :value="1"></el-option>
            <el-option label="已整改" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:riskcheck:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:riskcheck:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="隐患名称" min-width="200" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" @click="viewHandle(row.id)">{{ row.name }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="gradeId" label="隐患等级" width="100" align="center" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.gradeId==1" style="color:#909399">一般隐患</span>
            <span v-else-if="scope.row.gradeId==2" style="color:#F56C6C">重大隐患</span>
          </template>
        </el-table-column>
        <el-table-column prop="checker" label="检查人" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="checkDate" label="检查日期" width="100" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="statusId" label="检查状态" width="100" align="center" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.statusId==0" type="info" effect="plain">无需整改</el-tag>
            <el-tag v-if="scope.row.statusId==1" type="warning" effect="plain">整改中</el-tag>
            <el-tag v-if="scope.row.statusId==2" type="success" effect="plain">已整改</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="rectifyName" label="整改责任人" width="100" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="120">
          <template v-slot="{row}">
            <table-button role="delete" v-if="$hasPermission('opm:riskcheck:update')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:riskcheck:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>
<!--隐患排查-->
<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './riskcheck-add-or-update'
import ViewPage from './riskcheck-view'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/opm/riskCheck/page',
        getDataListIsPage: true,
        deleteURL: '/opm/riskCheck',
      },
      dataForm: {
        prjId: '',
        contractId: '',
        name: '', // 隐患名称
        statusId: '', // 检查状态
      },
      contractName: '',
    }
  },
  watch: {},
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.contractName = data.contract.name
      this.getDataList()
    },
    // 查看
    viewHandle(id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = id
        this.$refs.view.dataForm.prjId = this.dataForm.prjId
        this.$refs.view.dataForm.contractId = this.dataForm.contractId
        this.$refs.view.dataForm.contractName = this.contractName
        this.$refs.view.init()
      })
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.dataForm.contractName = this.contractName
        this.$refs.addOrUpdate.init()
      })
    },
    statusSelectChange() {
      if (this.dataForm.contractId) {
        this.getDataList()
      }
    }
  }
}
</script>