<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__riskcheck">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="隐患名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column prop="name" label="隐患名称" min-width="200" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" @click="viewHandle(row.id)">{{ row.name }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="gradeId" label="隐患等级" width="100" align="center" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.gradeId==1" style="color:#909399">一般隐患</span>
            <span v-else-if="scope.row.gradeId==2" style="color:#F56C6C">重大隐患</span>
          </template>
        </el-table-column>
        <el-table-column prop="checker" label="检查人" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="checkDate" label="检查日期" width="100" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="deadline" label="整改期限" width="100" align="center" sortable="custom"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="120">
          <template v-slot="{row}">
            <table-button tooltip="整改" icon="el-icon-check" v-if="$hasPermission('opm:riskcheck:save')" @click="addHandle(row)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add v-if="addVisible" ref="add" @refreshDataList="getDataList" @close="closeDialogHandle"></add>
    </div>
  </el-card>
</template>
<!--安全隐患待整改列表-->
<script>
import mixinViewModule from '@/mixins/view-module'
import Add from './riskrectify-add'
import ViewPage from './riskcheck-view'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/opm/riskCheck/page',
        getDataListIsPage: true,
        deleteURL: '/opm/riskCheck',
      },
      dataForm: {
        contractId: '',
        name: '', // 隐患名称
        statusId: '1', // 检查状态：1:整改中
        rectifyId: this.$store.state.user.id // 整改人Id
      },
      contractName: '',
      addVisible: false
    }
  },
  watch: {},
  components: {
    Add,
    ViewPage
  },
  methods: {
    // 标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.contractName = data.contract.name
      this.getDataList()
    },
    // 查看
    viewHandle(id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = id
        this.$refs.view.dataForm.contractId = this.dataForm.contractId
        this.$refs.view.dataForm.contractName = this.contractName
        this.$refs.view.init()
      })
    },
    // 隐患整改
    addHandle(row) {
      this.addVisible = true
      this.$nextTick(() => {
        this.$refs.add.dataForm.id = ''
        this.$refs.add.dataForm.mainId = row.id
        this.$refs.add.dataForm.rectifyId = row.rectifyId
        this.$refs.add.dataForm.contractId = this.dataForm.contractId
        this.$refs.add.dataForm.contractName = this.contractName
        this.$refs.add.init()
      })
    },
    closeDialogHandle() {
      this.addVisible = false
      this.viewVisible = false
    },
  }
}
</script>