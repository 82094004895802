<template>
  <el-dialog width="300px" v-dialog-drag :visible.sync="visible" title="年份定义" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-table v-loading="tableLoading" :data="dataList" highlight-current-row border>
      <el-table-column header-align="center" align="center" width="50">
        <template v-slot:header>
          <el-button type="text" @click="addRow()">新增</el-button>
        </template>
        <template v-slot="{row, $index}">
          <i class="el-icon-remove-outline" style="color:red;cursor:pointer;" @click="del(row, $index)"></i>
        </template>
      </el-table-column>
      <el-table-column prop="year" label="年份" header-align="center" align="center" show-overflow-tooltip>
        <template v-slot="{row}">
          <el-input-number v-model="row.year" :precision="0" :controls="false" label="申请数量" style="width: 100%"
            @change="(currentValue, oldValue) => {onYearChange(currentValue, oldValue, row)}">
          </el-input-number>
        </template>
      </el-table-column>
    </el-table>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  components: {},
  data () {
    return {
      visible: false,
      dataForm: {
        contractId: '',
      },
      dataList: [],
      tableLoading: false,
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.getInfo()
      })
    },
    // 获取信息
    getInfo () {
      this.tableLoading = true
      this.$http.get(
          '/opm/year/list',
          {
            params: {
              contractId: this.dataForm.contractId,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          this.$message.error(res.msg)
          return
        }
        this.dataList = res.data
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      }).finally(() => {
        this.tableLoading = false
      })
    },
    onYearChange(currentValue, oldValue, row) {
      this.$http[!row.id ? 'post' : 'put'](
          '/opm/year/',
          {
            id: row.id,
            contractId: this.dataForm.contractId,
            year: currentValue
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          row.year = oldValue
          return this.$message.error(res.msg)
        }
        row.id = res.data
        this.$message({
          message: '保存成功',
          type: 'success',
          duration: 1500,
          onClose: () => {
            // do nothing
          }
        })
      }).catch(() => {
        row.year = oldValue
      })
    },
    addRow() {
      this.dataList.push({})
    },
    del(row, index) {
      if (row.id) {
        this.$confirm(this.$t('prompt.info', { 'handle': this.$t('delete') }), this.$t('prompt.title'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          this.dataList.splice(index, 1)
          this.$http.delete(
              `/opm/year`,
              {
                'data': [row.id]
              }
          ).then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
              }
            })
          }).catch(() => {
            // do nothing
          })
        }).catch(() => {
          // do nothing
        })
      } else {
        this.dataList.splice(index, 1)
      }
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/scheduleUnitType/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {})
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>