<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__masterschedule">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.scheduleUnitName" placeholder="形象单元名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:masterschedule:save')" @click="batchAdd()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:masterschedule:delete')" @click="deleteHandle()"></toolbar-button>
            <toolbar-button name="年份定义" icon="el-icon-date" v-if="$hasPermission('opm:masterschedule:save') && this.dataForm.contractId" @click="openYearDefineDialog()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="scheduleUnitName" label="形象单元" min-width="200" fixed="left" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="unitName" label="单位" width="100" align="center" fixed="left" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="num" label="总工程量" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="amount" label="总金额(元)" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="startDate" label="开始日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="endDate" label="结束日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column v-if="yearList.length > 0" label="年度详细工程量" header-align="center">
          <el-table-column v-for="year in yearList" :key="year" :prop="'yearDetail.' + year" :label="year + '年'" width="120" align="right"></el-table-column>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="120">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:masterschedule:update')" @click="addOrUpdateHandle(row)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:masterschedule:delete')" @click="deleteHandle(row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <year-define v-if="yearDefineVisible" ref="yearDefine" @close="closeCustomDialogHandle"></year-define>
      <batch-add v-if="batchAddVisible" ref="batchAdd" @refreshDataList="getDataList" @close="closeCustomDialogHandle"></batch-add>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './masterschedule-add-or-update'
import ViewPage from './masterschedule-view'
import YearDefine from "./year-define";
import BatchAdd from "./masterschedule-batch-add";

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/masterSchedule/page',
        getDataListIsPage: true,
        exportURL: '/opm/masterSchedule/export',
        deleteURL: '/opm/masterSchedule',
      },
      dataForm: {
        prjId: '',
        contractId: '',
        scheduleUnitName: '',
      },
      yearDefineVisible: false,
      batchAddVisible: false,
      yearList: [],
      projectSelectShow: true,

      // 默认排序
      order:'asc',
      orderField:'start_date',
    }
  },
  components: {
    AddOrUpdate,
    ViewPage,
    YearDefine,
    BatchAdd,
  },
  methods:{
    // 标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getDataList()
      this.getYearList()
    },

    // 打开年份定义弹窗
    openYearDefineDialog() {
      this.yearDefineVisible = true
      this.$nextTick(() => {
        this.$refs.yearDefine.dataForm.contractId = this.dataForm.contractId
        this.$refs.yearDefine.init()
      })
    },
    closeCustomDialogHandle() {
      this.yearDefineVisible = false
      this.batchAddVisible = false
    },
    // 批量新增
    batchAdd () {
      this.batchAddVisible = true
      this.$nextTick(() => {
        this.$refs.batchAdd.dataForm.prjId = this.dataForm.prjId
        this.$refs.batchAdd.dataForm.contractId = this.dataForm.contractId
        this.$refs.batchAdd.init()
      })
    },
    getYearList() {
      this.$http.get(
          '/opm/year/list',
          {
            params: {
              contractId: this.dataForm.contractId,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          this.$message.error(res.msg)
          return
        }
        this.yearList = res.data.map((obj) => {
          return obj.year
        })
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    // 修改
    addOrUpdateHandle (row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = row.id
        this.$refs.addOrUpdate.scheduleUnitName = row.scheduleUnitName
        this.$refs.addOrUpdate.unitName = row.unitName
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>