<template>
  <el-dialog v-dialog-drag width="70%" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本信息" name="first">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="隐患名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="隐患名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同标段" prop="contractName">
                <el-input v-model="dataForm.contractName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查人" prop="checker">
                <el-input v-model="dataForm.checker" placeholder="检查人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查日期" prop="checkDate">
                <el-date-picker type="date" placeholder="检查日期" value-format="yyyy-MM-dd" v-model="dataForm.checkDate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查结果" prop="statusId">
                <el-select v-model="dataForm.statusId" placeholder="请选择" style="width: 100%;" @change="statusSelectChange">
                  <el-option label="无需整改" :value="0"></el-option>
                  <el-option label="需要整改" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="隐患等级" prop="gradeId">
                <el-radio-group v-model="dataForm.gradeId">
                  <el-radio :label="1">一般隐患</el-radio>
                  <el-radio :label="2">重大隐患</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="dataForm.statusId == 1">
            <el-col :span="12">
              <el-form-item label="整改责任人" prop="rectifyId">
                <user-select v-model="dataForm.rectifyId" :prj-id="dataForm.prjId" @input="rectifyUserChange" style="width: 100%" :value-show-parent="false" only-participant-user></user-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="整改期限" prop="deadline">
                <el-date-picker type="date" placeholder="整改期限" value-format="yyyy-MM-dd" v-model="dataForm.deadline" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item v-if="dataForm.rectifyId && dataForm.statusId == 1" label="整改单位" prop="deptId">
                <el-input v-model="dataForm.deptName" placeholder="加载中..." disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="隐患情况说明" prop="description">
                <el-input type="textarea" v-model="dataForm.description" :autosize="{ minRows: 3,maxRows:10}" placeholder="隐患情况说明"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      activeName: 'first',
      dataForm: {
        id: '',
        contractId: '',
        prjId: '',
        name: '',
        gradeId: '',
        rectifyId: '',
        deadline: '',
        checker: '',
        checkDate: '',
        description: '',
        statusId: '',
        deptId: '',
        deptName: ''
      },
    }
  },
  computed: {
    dataRule() {
      return {
        statusId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        checker: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        checkDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        rectifyId: [
          {required: this.dataForm.statusId == 1, message: this.$t('validate.required'), trigger: 'change'}
        ],
        deptId: [
          {required: this.dataForm.statusId == 1, message: this.$t('validate.required'), trigger: 'change'}
        ],
        gradeId: [
          {required: true, message: this.$t('validate.required'), trigger: 'change'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/riskCheck/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/riskCheck/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),

    rectifyUserChange(userId) {
      if (userId) {
        // 根据人员Id,获取人员的单位信息
        this.$http.get(`/sys/user/${this.dataForm.rectifyId}/dept`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          let dept = res.data
          if (dept) {
            this.dataForm.deptId = dept.deptId
            this.dataForm.deptName = dept.deptName
          }
        }).catch(() => {
          // do nothing
        })
      } else {
        this.dataForm.deptId = ''
        this.dataForm.deptName = ''
      }
    },

    // 检查结果选中项变化事件
    statusSelectChange(statusId) {
      // 检查结果是无需整改时，清空整改责任人、整改单位、整改期限
      if (statusId == 0) {
        this.dataForm.rectifyId = ''
        this.dataForm.deptId = ''
        this.dataForm.deptName = ''
        this.dataForm.deadline = ''
      }
    }
  }
}
</script>