<template>
  <el-dialog v-dialog-drag width="60%" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tabs v-model="activeName">
      <el-tab-pane label="隐患整改" name="first">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="整改日期" prop="rectifyDate">
                <el-date-picker type="date" placeholder="整改日期" value-format="yyyy-MM-dd" v-model="dataForm.rectifyDate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="整改状态" prop="statusId">
                <el-radio-group v-model="dataForm.statusId">
                  <el-radio :label="1">整改中</el-radio>
                  <el-radio :label="2">整改完成</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="整改情况说明" prop="description">
                <el-input type="textarea" v-model="dataForm.description" :autosize="{ minRows: 3,maxRows:10}" placeholder="整改情况说明"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-divider content-position="left">附件上传</el-divider>
              <my-upload-plain v-if="dataForm.id" :refId="dataForm.id"></my-upload-plain>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      activeName: 'first',
      dataForm: {
        id: '',
        contractId: '',
        mainId: '',
        rectifyId: '',
        description: '',
        rectifyDate: '',
        statusId: ''
      },
    }
  },
  computed: {
    dataRule() {
      return {
        statusId: [
          {required: true, message: this.$t('validate.required'), trigger: 'change'}
        ],
        rectifyDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'change'}
        ]
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getId()
      })
    },
    // 获取分布式ID
    getId() {
      this.$http.get('/opm/riskRectify/newId').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.id = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http['post']('/opm/riskCheck/reform', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>