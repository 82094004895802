<!-- 投资分析 -->
<template>
  <div>
    <!-- 第1行 -->
    <el-row :gutter="1">
      <el-col :span="24">
        <el-card>
          <!-- <div slot="header" class="header-default">
            <i class="el-icon-s-comment"></i><span> 项目总览</span>
          </div> -->
          <el-scrollbar style="height: 250px;">
            <el-row>
              <el-col :span="6">
                <el-card shadow="always" style="height: 250px; background-color: #e03997; padding: 4px; color: white">
                  <p style="font-weight: bold; margin: 0; padding: 0;">
                    在建项目：{{ zjxm.count }}个
                  </p>
                  <ul style="list-style: none; margin: 0; padding: 0; height: 50px;">
                    <li v-for="(txt, index) in zjxm.mx" :key="index">
                      {{ txt }}
                    </li>
                  </ul>
                  <div id="zjxmChart" v-echart-resize :style="{width: '100%', height: '150px'}"/>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card shadow="always" style="height: 250px; background-color: #FC9D99; padding: 4px; color: white">
                  <p style="font-weight: bold; margin: 0; padding: 0;">
                    投资总额 {{ tzze.total }}亿元
                  </p>
                  <ul style="list-style: none; margin: 0; padding: 0; height: 50px;">
                    <li>累计完成 {{ tzze.finished }}亿元</li>
                    <li>占总投资 {{ tzze.percentage }}</li>
                  </ul>
                  <div id="tzzeChart" v-echart-resize :style="{width: '100%', height: '150px'}"/>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card shadow="always" style="height: 250px; background-color: #26A65B; padding: 4px; color: white">
                  <p style="font-weight: bold; margin: 0; padding: 0;">
                    年计划投资 {{ ndjh.plan }}亿元
                  </p>
                  <ul style="list-style: none; margin: 0; padding: 0; height: 50px;">
                    <li>已完成 {{ ndjh.finished }}亿元</li>
                    <li>完成比例 {{ ndjh.percentage }}</li>
                  </ul>
                  <div id="ndjhChart" v-echart-resize :style="{width: '100%', height: '150px'}"/>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card shadow="always" style="height: 250px; background-color: #AEDD81; padding: 4px; color: white">
                  <p style="font-weight: bold; margin: 0; padding: 0;">
                    变更总额 {{ bgze.total }}亿元
                  </p>
                  <ul style="list-style: none; margin: 0; padding: 0; height: 50px;">
                    <li>占总投资 {{ bgze.percentage }}</li>
                  </ul>
                  <div id="bgzeChart" v-echart-resize :style="{width: '100%', height: '150px'}"/>
                </el-card>
              </el-col>
            </el-row>
          </el-scrollbar>
        </el-card>
      </el-col>
    </el-row>
    <!-- 第2行 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 章节计量统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <project-select v-model="chapterChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="measChapterChartHandle"></project-select>
                  <!-- <contract-select v-model="chapterChart.contractId" :prj-id="chapterChart.prjId" ref="contractSelect" placeholder="合同标段选择" @change="measChapterChartHandle" :defaultSelect="true" style="width: 200px;"></contract-select> -->
                </div>
                <div style="width: 70%; float: left; overflow: hidden;color:red">
                  <div id="chapterChart" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
                <div style="color:red;width: 30%; float: left; overflow: hidden">
                  <div id="chapterChartTotal" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 第3行 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 计量进度统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <project-select v-model="measScheChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="measScheChartHandle"></project-select>
                </div>
                <div v-echart-resize id="measScheChart" :style="{width: '100%', height: '260px'}"/>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 第4行 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 产值统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <!-- <div>
                  <project-select v-model="outputChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="outputChartHandle"></project-select>
                </div> -->
                <!-- 产值统计，改为按项目维度的 -->
                <div style="width: 100%; float: left; overflow: hidden;color:red">
                  <div id="outputChart" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
// 投资分析
import {addDynamicRoute} from '@/router'
import { HashMap } from '@/utils'
export default {
  data () {
    return {
      // 在建项目概览
      zjxm: {
        count: '',
        mx: [],
        instance: null
      },
      // 投资总额
      tzze: {
        total: 0,// 投资总额
        finished: '',// 已完成投资额
        percentage: '',// 已完成投资比例
        instance: null
      },
      // 年度投资计划
      ndjh: {
        plan: 0,// 年计划总投资
        finished: '',// 年度完成投资额
        percentage: '',// 完成投资比例
        instance: null
      },
      // 变更总额
      bgze: {
        total: 0,
        percentage: '',
        instance: null
      },
      // 百章计量统计
      chapterChart: {
        prjId: '',
        // contractId: '',
        instanceChapter: null,
        instanceTotal: null
      },
      // 产值统计
      outputChart: {
        prjId: '',
        instance: null
      },
      measScheChart: {
        prjId: '',
        instance: null
      },
      chartLoadingText:{// 图表loading效果
        text: '统计中，请稍候...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(3,3,8,0.1)',
        zlevel: 0,
      },
    }
  },
  created () {
    this.$nextTick(() => {
      this.echartsRegisterCustomShape()
      // 章节计量统计图表
      this.chapterChart.instanceChapter = this.$echarts.init(document.getElementById('chapterChart'))
      this.chapterChart.instanceTotal = this.$echarts.init(document.getElementById('chapterChartTotal'))
      //  计量进度统计
      this.measScheChart.instance = this.$echarts.init(document.getElementById('measScheChart'))
      //  产值统计
      this.outputChart.instance = this.$echarts.init(document.getElementById('outputChart'))
      //  在建项目
      this.zjxm.instance = this.$echarts.init(document.getElementById('zjxmChart'))
      //  投资总额
      this.tzze.instance = this.$echarts.init(document.getElementById('tzzeChart'))
      //  年度计划
      this.ndjh.instance = this.$echarts.init(document.getElementById('ndjhChart'))
      //  变更总额
      this.bgze.instance = this.$echarts.init(document.getElementById('bgzeChart'))
    })
  },
  mounted() {
    this.$nextTick(() => {
      // 项目总览
      this.getOverview()
      // 产值统计
      this.outputChartHandle()
    })
  },
  methods: {
    // 项目总览
    getOverview () {
      if (this.$hasPermission('da:investAnalysis')) {
        this.$http.get(`/opm/da/overview`).then(({data: res}) => {
          if (res.code === 0) {
            // 在建项目数
            this.zjxm.count = res.data.zjxm_count
            // 在建项目分类明细
            this.zjxm.mx = res.data.zjxm_mx
            // 各类型在建项目统计饼形图
            this.showZjxmChart(res.data.zjxm_chart)

            // 投资总额
            this.tzze.total = res.data.tzze_total
            // 已完成投资额
            this.tzze.finished = res.data.tzze_finished
            // 已完成投资比例
            this.tzze.percentage = res.data.tzze_percentage + '%'
            // 投资总额完成率仪表盘
            this.showGaugeBoard('投资完成', res.data.tzze_percentage, this.tzze.instance)

            // 年计划投资总额
            this.ndjh.plan = res.data.ndjh_plan
            // 年完成投资额
            this.ndjh.finished = res.data.ndjh_finished
            // 完成比例
            this.ndjh.percentage = res.data.ndjh_percentage + '%'
            // 年投资完成率仪表盘
            this.showGaugeBoard('投资完成', res.data.ndjh_percentage, this.ndjh.instance)

            // 变更总额
            this.bgze.total = res.data.bgze_total
            this.bgze.percentage = res.data.bgze_percentage + '%'
            // 变更总额占投资总额占比饼形图
            this.showBgzeChart(res.data.tzze_total_wan, res.data.bgze_total_wan)
          }
        }).catch(() => {
          //
        })
      }
    },
    // 各类型在建项目饼形图
    showZjxmChart (data) {
      let seriesData = []
      for (const key in data) {
        seriesData.push({
          name: key,
          value: data[key]
        })
      }
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: function (p) {
            return p.name + ' : ' + p.value + '个'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '65%'],
            data: seriesData,
            color: this.getLinears()
          },
        ],
      }
      if (this.zjxm.instance) {
        this.zjxm.instance.clear()
        this.zjxm.instance.setOption(option)
      }
    },
    // 完成率仪表盘（公用的）
    showGaugeBoard(title, percentage, instance) {
      var colorTemplate1 = [[0.2, "rgba(255,0,0,0.8)"], [0.8, "rgba(0,255,255,0.8)"], [1, "rgba(0,255,0,0.8)"]];
      var data1 = [{
        name: title,
        value: percentage,
      }]
      var option = {
        tooltip: { 
          show: true,
          formatter: "{b}：{c}%",
        },
        series: [
          {
            name: "仪表盘",
            type: "gauge", // 系列类型
            radius: "80%", // 参数:number, string。 仪表盘半径,默认 75% ，可以是相对于容器高宽中较小的一项的一半的百分比，也可以是绝对的数值。
            center: ["50%", "55%"], // 仪表盘位置(圆心坐标)
            startAngle: 225, // 仪表盘起始角度,默认 225。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
            endAngle: -45, // 仪表盘结束角度,默认 -45
            clockwise: true, // 仪表盘刻度是否是顺时针增长,默认 true。
            min: 0, // 最小的数据值,默认 0 。映射到 minAngle。
            max: 100, // 最大的数据值,默认 100 。映射到 maxAngle。
            splitNumber: 10, // 仪表盘刻度的分割段数,默认 10。
            
            axisLine: { // 仪表盘轴线(轮廓线)相关配置。
              show: true, // 是否显示仪表盘轴线(轮廓线),默认 true。
              lineStyle: { // 仪表盘轴线样式。
                color: colorTemplate1,  //仪表盘的轴线可以被分成不同颜色的多段。每段的  结束位置(范围是[0,1]) 和  颜色  可以通过一个数组来表示。默认取值：[[0.2, '#91c7ae'], [0.8, '#63869e'], [1, '#c23531']]
                opacity: 1, //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                width: 30, //轴线宽度,默认 30。
                shadowBlur: 20, //(发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
                shadowColor: "#fff", //阴影颜色。支持的格式同color。
              }
            },
            
            splitLine: { // 分隔线样式。
              show: true, // 是否显示分隔线,默认 true。
              length: 30, // 分隔线线长。支持相对半径的百分比,默认 30。
              lineStyle: { // 分隔线样式。
                color: "#eee", //线的颜色,默认 #eee。
                opacity: 1, //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                width: 2, //线度,默认 2。
                type: "solid", //线的类型,默认 solid。 此外还有 dashed,dotted
                shadowBlur: 10, //(发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
                shadowColor: "#fff", //阴影颜色。支持的格式同color。
              }
            },
            
            axisTick: { // 刻度(线)样式。
              show: true, // 是否显示刻度(线),默认 true。
              splitNumber: 5, // 分隔线之间分割的刻度数,默认 5。
              length: 8, // 刻度线长。支持相对半径的百分比,默认 8。
              lineStyle: { // 刻度线样式。   
                color: "#eee", //线的颜色,默认 #eee。
                opacity: 1, //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                width: 1, //线度,默认 1。
                type: "solid", //线的类型,默认 solid。 此外还有 dashed,dotted
                shadowBlur: 10, //(发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
                shadowColor: "#fff", //阴影颜色。支持的格式同color。
              },
            },
                
            axisLabel: { // 刻度标签。
              show: false, // 是否显示标签,默认 true。
              distance: 5, // 标签与刻度线的距离,默认 5。
              color: "#fff", // 文字的颜色,默认 #fff。
              fontSize: 12, // 文字的字体大小,默认 5。
              formatter: "{value}", // 刻度标签的内容格式器，支持字符串模板和回调函数两种形式。 示例:// 使用字符串模板，模板变量为刻度默认标签 {value},如:formatter: '{value} kg'; // 使用函数模板，函数参数分别为刻度数值,如formatter: function (value) {return value + 'km/h';}
            },
            
            pointer: { // 仪表盘指针。
              show: true, // 是否显示指针,默认 true。
              length: "70%", // 指针长度，可以是绝对数值，也可以是相对于半径的百分比,默认 80%。
              width: 5, // 指针宽度,默认 8。
            },
            
            itemStyle: { // 仪表盘指针样式。
              color: "auto", // 指针颜色，默认(auto)取数值所在的区间的颜色
              opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
              borderWidth: 0, // 描边线宽,默认 0。为 0 时无描边。
              borderType: "solid", // 柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
              borderColor: "#000", // 图形的描边颜色,默认 "#000"。支持的颜色格式同 color，不支持回调函数。
              shadowBlur: 10, // (发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
              shadowColor: "#fff", // 阴影颜色。
            },
            
            emphasis: { // 高亮的 仪表盘指针样式
              itemStyle: {
                // 高亮 和正常  两者具有同样的配置项,只是在不同状态下配置项的值不同。
              }
            },
            
            title: { // 仪表盘标题
              show: false, // 是否显示标题
              offsetCenter: [0, "80%"],//相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
              color: "#fff", // 文字的颜色,默认 #333。
              fontSize: 25, // 文字的字体大小,默认 15。
            },
            
            detail: { // 仪表盘详情，用于显示数据。
              show: true, // 是否显示详情,默认 true。
              offsetCenter: [0, "70%"],// 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
              color: "#fff", // 文字的颜色,默认 auto。
              fontSize: 25, // 文字的字体大小,默认 15。
              formatter: "{value}%",  // 格式化函数或者字符串
            },
            
            data: data1
          }
        ]
      }
      if (instance) {
        instance.clear()
        instance.setOption(option)
      }
    },
    // 变更总额占投资总额饼形图
    showBgzeChart (tzze, bgze) {
      let seriesData = [{
        name: '投资总额',
        value: tzze
      }, {
        name: '变更总额',
        value: bgze
      }]
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: "{b}：{c}万",
          // formatter: "{a} <br/>{b}: {c}万 ({d}%)"
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '65%'],
            data: seriesData,
            color: this.getLinears().reverse()
          },
        ],
      }
      if (this.bgze.instance) {
        this.bgze.instance.clear()
        this.bgze.instance.setOption(option)
      }
    },
    // 章节计量统计
    measChapterChartHandle () {
      if (this.$hasPermission('da:investAnalysis')) {
        let chartData = {
          boqChapters: [],
          boqAmounts: [],
          latestMeasAmount: []
        }
        this.chapterChart.instanceChapter.showLoading(this.chartLoadingText);
        this.chapterChart.instanceTotal.showLoading(this.chartLoadingText);
        this.$http.get(`/mps/da/chapterMeasCount`,{
          params: {
            prjId: this.chapterChart.prjId,
            // contractId: this.chapterChart.contractId
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              message: res.msg,
              type: 'error',
              duration: 3000,
              onClose: () => {
                // do nothing
              }
            })
          }
          chartData = res.data
          this.setChapterChartOption(chartData)
          this.chapterChart.instanceChapter.hideLoading()
          this.chapterChart.instanceTotal.hideLoading()
        }).catch(() => {
          // do nothing
        })
      }
    },
    // 章节计量统计
    setChapterChartOption (chartData) {
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter (params) {
            let relVal = params[0].name;
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value.toLocaleString()} ${i < l - 1 ? '万' : ''}`;
            }
            return relVal;
          }
        },
        legend: {
          data: ['截止本期计量', '合同金额', '完成率'],
        },
        grid:{
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        xAxis: {
          data: chartData.boqChapters,
          axisTick:{
            show:false,
          },
        },
        yAxis: [
          {
            splitLine: {show: false},
            axisLine: {
              show: true,
            },
            axisLabel:{
              formatter:'{value} ',
            },
            axisTick:{
              show: true,
            },
          },
          {
            splitLine: {show: false},
            axisLine: {
              show: true,
            },
            axisLabel:{
              formatter:'{value} ',
            },
            axisTick:{
              show: true,
            },
          }
        ],
        series: [
          {
            name: '截止本期计量',
            type: 'bar',
            // stack: 'meas',
            barWidth: 15,
            itemStyle: {
              barBorderRadius: 5,
              //颜色渐变
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#9370d4'},
                    {offset: 0.5, color: '#6690dd'},
                    {offset: 1, color: '#41a9e3'}
                  ]
              )
            },
            data: chartData.latestMeasAmount,
          },
          {
            name: '合同金额',
            type: 'bar',
            // stack: 'meas',
            barGap: '-100%',
            barWidth: 15,
            itemStyle: {
              barBorderRadius: 5,
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: 'rgba(89,68,136,1)'},
                    // {offset: 0.2, color: 'rgba(156,107,211,0.5)'},
                    {offset: 1, color: 'rgba(89,68,136,0.1)'}
                  ]
              )
            },
            z: -12,
            data: chartData.boqAmounts
          },
          {
            name: '完成率',
            type: 'line',
            smooth: true,
            showAllSymbol: true,
            symbol: 'emptyCircle',
            symbolSize: 8,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color:'#e043b6'},
            },
            data: chartData.percentages
          },
        ]
      }
      if (this.chapterChart.instanceChapter) {
        this.chapterChart.instanceChapter.clear()
        this.chapterChart.instanceChapter.setOption(option)
      }
      // 百章计量完成情况统计
      let boqAmount = 1 // 合同额
      let latestMeasAmount = 0 // 计量金额
      if (chartData.boqAmounts || chartData.boqAmounts.length > 0) {
        for (let i = 0; i < chartData.boqAmounts.length; i++) {
          boqAmount = boqAmount + chartData.boqAmounts[i]
        }
      }
      if (chartData.latestMeasAmount || chartData.latestMeasAmount.length > 0) {
        for (let i = 0; i < chartData.latestMeasAmount.length; i++) {
          latestMeasAmount = latestMeasAmount + chartData.latestMeasAmount[i]
        }
      }
      var option2 = {
        tooltip: {
          trigger: 'item',
          formatter: function (p) {
            let value = p.value
            if (value > 999) {
              let parts = value.toString().split('.')
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,',')
              value = parts.join('.')
            }
            return p.seriesName + '<br/>' + p.name + ' : ' + value + '万 (' + p.percent + '%)'
          }
          // formatter: "{a} <br/>{b}: {c}万 ({d}%)"
        },
        color: ['#1890ff', '#7e8e98'],
        legend: {
          orient: 'vertical',
          left: 'left',
          data: ['已完成','未完成']
        },
        series: [
          {
            name: '计量',
            type: 'pie',
            radius: ['35%','50%'],
            data: [
              {
                value: latestMeasAmount,
                name: '已完成',
                // itemStyle: {
                //   normal: {color: "#66FF33"},
                //   emphasis: {color: "#66CC33"}
                // }
              },
              {
                value: boqAmount - latestMeasAmount < 0 ? 0 : boqAmount - latestMeasAmount,
                name: '未完成',
                // itemStyle: {
                //   color: "#FF00FF",
                //   emphasis: {color: "#CC0066"}
                // }
              },
            ],
            label: {
              normal: {
                formatter: "{b}\n{d}%",
                // formatter: "\n{d}%",
                backgroundColor: "rgba(255, 147, 38, 0)",
                borderColor: "transparent",
                borderRadius: 4,
                textStyle: {
                  color: "#4a4a4a",
                  fontSize: 16
                }
              }
            },
          },
        ],
      }
      if (this.chapterChart.instanceTotal) {
        this.chapterChart.instanceTotal.clear()
        this.chapterChart.instanceTotal.setOption(option2)
      }
    },
    // 计量进度统计
    measScheChartHandle () {
      if (this.$hasPermission('da:investAnalysis')) {
        let chartData = {
          contractNames: [],
          periodNames: [],
          measMx: []
        }
        this.measScheChart.instance.showLoading(this.chartLoadingText);
        this.$http.get(`/mps/da/measScheCount`,{
          params: {
            prjId: this.measScheChart.prjId
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              message: res.msg,
              type: 'error',
              duration: 3000,
              onClose: () => {
                // do nothing
              }
            })
          }
          chartData = res.data
          this.setMeasScheChartOption(chartData)
          this.measScheChart.instance.hideLoading()
        }).catch(() => {
          // do nothing
        })
      }
    },
    // 计量进度统计
    setMeasScheChartOption (chartData) {
      let series = []
      if (chartData.measMx && chartData.measMx.length > 0) {
        for (var i = 0; i < chartData.measMx.length; i++) {
          let measSche = chartData.measMx[i];
          series.push({
            name: measSche.contractName || '',
            type: 'line',
            data: measSche.latestMeasAmountTotal || [],
            itemStyle: {
              normal: {
                // color: "#00A3E0", //改变折线点的颜色
                lineStyle: {
                  color: "#00A3E0", //改变折线颜色
                },
                label: {
                  show: true,
                  position: 'top',
                }
              }
            },
            smooth: true
          })
        }
      }
      var option = {
        title: {
          //text: '章节计量统计'
        },
        grid: {// 控制上下左右边距
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter (params) {
            let relVal = params[0].name;
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value.toLocaleString()} 万`;
            }
            return relVal;
          }
        },
        legend: {
          data: chartData.contractNames || []
        },
        xAxis: {
          type: 'category',
          data: chartData.periodNames || []
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 万'
          }
        },
        series: series
      };
      if (this.measScheChart.instance) {
        this.measScheChart.instance.clear()
        this.measScheChart.instance.setOption(option)
      }
    },
    // 产值统计
    outputChartHandle () {
      if (this.$hasPermission('da:scheduleAnalysis')) {
        let chartData = {
          // 标段名/项目名
          names: [],
          // 合同额
          amounts: [],
          // 变更后金额
          changedAmounts: [],
          // 累计完成
          totalFinished: [],
          // 年度计划产值
          annualPlan: [],
          // 本年完成产值
          annualFinished: []
        }
        this.outputChart.instance.showLoading(this.chartLoadingText);
        this.$http.get(`/opm/da/outputChart`,{
          params: {
            // prjId: this.outputChart.prjId,
            // 首页产值统计改为项目维度的
            prjId: 'all'
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              message: res.msg,
              type: 'error',
              duration: 3000,
              onClose: () => {
                // do nothing
              }
            })
          }
          chartData = res.data
          this.setOutputChartOption(chartData)
          this.outputChart.instance.hideLoading()
        }).catch(() => {
          // do nothing
        })
      }
    },
    // 产值统计
    setOutputChartOption (chartData) {
      const option = {
        title: {
          // text: '产值统计'
        },
        grid: {// 控制上下左右边距
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter (params) {
            let relVal = params[0].name;
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value.toLocaleString()} 万`;
            }
            return relVal;
          }
        },
        color:['rgba(11,42,106,.8)', '#3B80E2', '#22a2ad', '#2bb026', '#7c8019'],
        legend: {
          data: ['合同金额', '变更后合同额', '累计完成', '年度计划产值', '本年完成产值']
        },
        xAxis: {
          data: chartData.names
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 万'
          }
        },
        // todo 有数据后可以尝试把相关联的柱子叠加起来，对比效果更明显，比如把累计完成和合同额叠加起来（颜色需要调整）
        series: [
          {
            name: '合同金额',
            type: 'custom',
            renderItem: function(params, api) {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = -2 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: 'rgba(7,29,97,.6)'
                  }
                }, {
                  type: 'CubeRight',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: 'rgba(10,35,108,.7)'
                  }
                }, {
                  type: 'CubeTop',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: 'rgba(11,42,106,.8)'
                  }
                }]
              }
            },
            data: chartData.amounts
          },
          {
            name: '变更后合同额',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = -1 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3B80E2'
              }, {
                offset: 1,
                color: '#49BEE5'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  }]
              }
            },
            data: chartData.changedAmounts
          },
          {
            name: '累计完成',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#22a2ad'
              }, {
                offset: 1,
                color: '#49e5d3'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  }]
              }
            },
            data: chartData.totalFinished
          },
          {
            name: '年度计划产值',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 1 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#2bb026'
              }, {
                offset: 1,
                color: '#75e549'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  }]
              }
            },
            data: chartData.annualPlan
          },
          {
            name: '本年完成产值',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 2 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#7c8019'
              }, {
                offset: 1,
                color: '#d0e549'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  }]
              }
            },
            data: chartData.annualFinished
          },
        ],
      }
      if (this.outputChart) {
        this.outputChart.instance.clear()
        this.outputChart.instance.setOption(option)
      }
    },
    // 根据当前年份动态生成最近五年的年份选项
    makeYearOptions() {
      let year = new Date().getFullYear()
      let arr = []
      for (let i = 0; i < 4; i++) {
        arr.push(year - i)
      }
      arr.sort()
      return arr
    },
    // 预设十个渐变色，统计图可直接调用该方法获取颜色
    getLinears() {
      let linears = []

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#02bcff'
        }, {
          offset: 1, color: '#0081ff' // 海蓝
        }],
        global: false
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00FF00'
        }, {
          offset: 1, color: '#00BB00' // 亮绿
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFB6C1'
        }, {
          offset: 1, color: '#FF69B4' // 桃粉
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCC99'
        }, {
          offset: 1, color: '#fbbd08' // 明黄
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF'
        }, {
          offset: 1, color: '#e54d42' // 嫣红
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00CCFF'
        }, {
          offset: 1, color: '#00CCFF' // 亮蓝
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#99FFFF'
        }, {
          offset: 1, color: '#00FFFF' // 橄榄
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFDDAA'
        }, {
          offset: 1, color: '#EE7700' // 棕褐
        }],
      })
      
      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF'
        }, {
          offset: 1, color: '#FF00FF' // 粉色
        }],
      })

      return linears
    },
    // echarts注册自定义图形
    echartsRegisterCustomShape() {
      const CubeLeft = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c0 = [shape.x, shape.y]
          const c1 = [shape.x - 9, shape.y - 9]
          const c2 = [xAxisPoint[0] - 9, xAxisPoint[1] - 9]
          const c3 = [xAxisPoint[0], xAxisPoint[1]]
          ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1])
              .closePath()
        }
      })
      const CubeRight = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c1 = [shape.x, shape.y]
          const c2 = [xAxisPoint[0], xAxisPoint[1]]
          const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9]
          const c4 = [shape.x + 18, shape.y - 9]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1])
              .closePath()
        }
      })
      const CubeTop = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          if (shape.y - shape.xAxisPoint[1] == 0) {
            return
          }
          const c1 = [shape.x, shape.y]
          const c2 = [shape.x + 18, shape.y - 9]
          const c3 = [shape.x + 9, shape.y - 18]
          const c4 = [shape.x - 9, shape.y - 9]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1])
              .closePath()
        }
      })
      this.$echarts.graphic.registerShape('CubeLeft', CubeLeft)
      this.$echarts.graphic.registerShape('CubeRight', CubeRight)
      this.$echarts.graphic.registerShape('CubeTop', CubeTop)
    }
  }
}
</script>
<style scoped>
  ::v-deep .el-card {
    cursor: pointer;
  }

  ::v-deep .el-card .el-card__header {
    padding: 0;
    height: 30px;
    color: white;
  }

  ::v-deep .el-card__body {
    padding: 1px !important;
  }

  ::v-deep .header-default {
    background: #8dc63f;
    padding: 5px;
  }

  ::v-deep .header-blue {
    background: #409EFF;
    padding: 5px;
  }

  ::v-deep .header-green {
    background: #67C23A;
    padding: 5px;
  }

  ::v-deep .header-brown {
    background: #E6A23C;
    padding: 5px;
  }

  ::v-deep .header-red {
    background: #F56C6C;
    padding: 5px;
  }

  ::v-deep .scrollbar-card {
    height: 200px;
  }

  ::v-deep .span-danger {
    font-weight: bold;
    font-size: 16px;
    color: red;
  }

  ::v-deep .span-warn {
    font-weight: bold;
    font-size: 16px;
    color: #E6A23C;
  }

  ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style-type: none;
  }

  li {
    list-style-type: none;
    padding-top: 5px;
  }

  .grid-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100px;
  }

  .grid-con-icon-bg-blue {
    background: #2d8cf0;
  }

  .grid-con-icon-bg-green {
    background: #64d572;
  }

  .grid-con-icon-bg-orange {
    background: #f25e43;
  }

  .grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
  }

  .grid-cont-right {
    padding-left: 5px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-size: 16px;
    color: #999;
  }
  .myMapAngle {
    display: inline-block;
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: -60px;
    border: 14px solid;
    left: 15px;
    bottom: -25px;
    opacity: 0.8;
    border-color: #333333 transparent transparent transparent;
  }
</style>