<template>
  <div>
    <el-dialog v-dialog-drag :visible.sync="visible" title="新增" width="90%" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
      <vxe-table id="vxeTable" keep-source ref="table"
                 size="mini"
                 border
                 :loading="tableLoading"
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataList"
                 header-align="center"
                 :max-height="tableHeight"
                 :edit-rules="editRules"
                 :edit-config="{trigger: 'click', mode: 'row', showStatus:true}">
        <vxe-column type="seq" title="序号" width="80" align="center" fixed="left">
          <template v-slot:header>
            <vxe-button type="button" status="primary" content="添加" round @click="batchAdd()"></vxe-button>
          </template>
        </vxe-column>
        <vxe-column field="name" title="形象单元" min-width="150" fixed="left" ></vxe-column>
        <vxe-column field="unitName" title="单位" align="center" width="100"></vxe-column>
        <vxe-column field="num" title="计划工程量" align="right" header-align="center" :edit-render="{name: '$input', props: {type: 'number', controls: false}}" width="150">
        </vxe-column>
        <vxe-column field="amount" title="计划金额(元)" align="right" header-align="center" :edit-render="{name: '$input', props: {type: 'number', controls: false}}" width="150"></vxe-column>
        <vxe-column field="startDate" title="开始日期" align="center" :edit-render="{name: '$input', props: {type: 'date'}}" width="120"></vxe-column>
        <vxe-column field="endDate" title="结束日期" align="center" :edit-render="{name: '$input', props: {type: 'date'}}" width="120"></vxe-column>
        <vxe-colgroup v-if="yearList.length > 0" title="各年度计划工程量" align="right" header-align="center" >
          <vxe-column v-for="year in yearList" :key="year" :field="'yearDetail.' + year" :title="year + '年'" :edit-render="{name: '$input', props: {type: 'number', controls: false}}" width="100"></vxe-column>
        </vxe-colgroup>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row, rowIndex}">
            <el-button type="text" size="small" @click="removeRow(row, rowIndex)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <scheduleunit-multi-select v-if="batchSelectVisible" ref="batchSelect" @submit="addDataToList" @close="dialogClose"></scheduleunit-multi-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        prjId: '',
        contractId: '',
      },
      tableHeight: 400,
      editRules: {},
      dataList: [],
      batchSelectVisible: false,
      // 缓存已有的形象单元id列表，避免新增时重复添加
      existIdList: null,
      tableLoading: false,
      yearList: [],
    }
  },
  mounted() {
    this.$nextTick(()=>{
      //  表格高度设置
      this.tableHeight=window.innerHeight-280
    })
  },
  components: {
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.getYearInfo()
      })
    },
    // 获取信息
    getYearInfo () {
      this.tableLoading = true
      this.$http.get(
          '/opm/year/list',
          {
            params: {
              contractId: this.dataForm.contractId,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.yearList = res.data.map((obj) => obj.year)
        this.yearList
        this.tableLoading = false
      }).catch(() => {})
    },
    // 打开多选弹窗
    batchAdd() {
      // 如果已存在的形象单元id列表没有缓存，则先查询并将其缓存，避免重复添加
      if (this.existIdList == null) {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.get(
            '/opm/masterSchedule/existUnitIds',
            {
              params: {
                contractId: this.dataForm.contractId,
              }
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.existIdList = res.data
          this.batchSelectVisible = true
          this.$nextTick(() => {
            this.$refs.batchSelect.dataForm.prjId = this.dataForm.prjId
            this.$refs.batchSelect.existIdList = this.existIdList
            this.$refs.batchSelect.init()
          })
        }).catch(() => {
        }).finally(() => {
          loading.close()
        })
      } else {
        this.batchSelectVisible = true
        this.$nextTick(() => {
          this.$refs.batchSelect.dataForm.prjId = this.dataForm.prjId
          this.$refs.batchSelect.existIdList = this.existIdList
          this.$refs.batchSelect.init()
        })
      }
    },
    dialogClose() {
      this.batchSelectVisible = false
    },
    // 批量选择形象单元的回调方法
    addDataToList(result) {
      result.forEach(function(obj) {
        obj.yearDetail = {}
      })
      this.dataList.push(...result)
      // 将选择的数据id添加到已存在的列表中
      this.existIdList.push(...(result.map((obj) => obj.id)))
    },
    // 表单提交
    dataFormSubmitHandle: debounce(async function () {
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.dataList.forEach(function(obj) {
        obj.scheduleUnitId = obj.id
      })
      this.$http.post(
          '/opm/masterSchedule/batchSave',
          {
            contractId: this.dataForm.contractId,
            dataList: this.dataList
          },
          this.dataList
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {}).finally(() => {
        loading.close()
      })
    }, 1000, { 'leading': true, 'trailing': false }),
    // 删除行
    removeRow(row, rowIndex) {
      this.dataList.splice(rowIndex, 1)
    }
  }
}
</script>