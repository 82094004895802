<template>
  <el-dialog v-dialog-drag :visible.sync="visible" title="修改" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="形象单元" prop="scheduleUnitName">
            <el-input v-model="scheduleUnitName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单位" prop="unitName">
            <el-input v-model="unitName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划工程量" prop="num">
            <el-input-number v-model="dataForm.num" :controls="false" placeholder="总计划工程量" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划金额" prop="amount">
            <el-input-number v-model="dataForm.amount" data-unit="元" placeholder="总计划金额" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始日期" prop="startDate">
            <el-date-picker
              v-model="dataForm.startDate"
              type="date"
              placeholder="开始日期"
              value-format="yyyy-MM-dd" :clearable="false" style="width: 100%">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束日期" prop="endDate">
            <el-date-picker
              v-model="dataForm.endDate"
              type="date"
              placeholder="结束日期"
              value-format="yyyy-MM-dd" :clearable="false" style="width: 100%">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <vxe-table v-if="yearList.length > 0" id="vxeTable" keep-source ref="table"
                 size="mini"
                 border
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="[dataForm.yearDetail]"
                 header-align="center"
                 :edit-config="{trigger: 'click', mode: 'row', showStatus:true}">
        <vxe-colgroup title="各年度计划工程量">
          <vxe-column v-for="year in yearList" :key="year" :field="year + ''" :title="year + '年'" header-align="center" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}" width="150"></vxe-column>
        </vxe-colgroup>
      </vxe-table>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        scheduleUnitId: '',
        num: '',
        amount: '',
        startDate: '',
        endDate: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
        yearDetail: {}
      },
      scheduleUnitName: '',
      unitName: '',
      yearList: [],
    }
  },
  computed: {
    dataRule () {
      return {
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/masterSchedule/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.getYearList()
      }).catch(() => {})
    },
    getYearList() {
      this.$http.get(
          '/opm/year/list',
          {
            params: {
              contractId: this.dataForm.contractId,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          this.$message.error(res.msg)
          return
        }
        this.yearList = res.data.map((obj) => {
          return obj.year
        })
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        // yearDetail因为vxetable的关系多了一个键值对 （_X_ROW_KEY：？？），需要将其删除
        delete this.dataForm.yearDetail['_X_ROW_KEY']
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/masterSchedule/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {})
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>