<template>
  <el-dialog v-dialog-drag :visible.sync="visible" width="70%" v-on="$listeners">
    <el-tabs>
      <el-tab-pane label="检查记录">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="隐患名称" prop="name">
                <el-input v-model="dataForm.name" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同标段" prop="contractName">
                <el-input v-model="dataForm.contractName" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查人" prop="checker">
                <el-input v-model="dataForm.checker" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查日期" prop="checkDate">
                <el-date-picker readonly type="date" value-format="yyyy-MM-dd" v-model="dataForm.checkDate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查结果" prop="statusId">
                <el-select v-model="dataForm.statusId" style="width: 100%;" disabled>
                  <el-option label="无需整改" :value="0"></el-option>
                  <el-option label="整改中" :value="1"></el-option>
                  <el-option label="已整改" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="隐患等级" prop="gradeId">
                <el-radio-group v-model="dataForm.gradeId" disabled>
                  <el-radio :label="1">一般隐患</el-radio>
                  <el-radio :label="2">重大隐患</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="dataForm.statusId != 0">
            <el-col :span="12">
              <el-form-item label="整改责任人" prop="rectifyId">
                <el-input v-model="dataForm.rectifyName" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="整改期限" prop="deadline">
                <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.deadline" style="width: 100%;" readonly></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="整改单位" prop="deptId">
                <el-input v-model="dataForm.deptName" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="隐患情况说明" prop="description">
                <el-input type="textarea" v-model="dataForm.description" :autosize="{ minRows: 3,maxRows:10}" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <my-upload-plain :refId="dataForm.id" :view="true"></my-upload-plain>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="整改记录">
        <el-timeline>
          <el-timeline-item v-for="obj in rectifyList" :key="obj.id" :color="obj.color" :timestamp="obj.rectifyDate + ' ' + obj.rectifyName" placement="top">
            <el-card>
              <h4 :style="{color: obj.color}">{{ obj.statusContent }} </h4>
              <p>{{ obj.description }}</p>
              <my-upload-plain :key="obj.id" :refId="obj.id" :view="true"></my-upload-plain>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        prjId: '',
        name: '',
        gradeId: '',
        rectifyId: '',
        deadline: '',
        checker: '',
        checkDate: '',
        description: '',
        statusId: '',
        rectifyName: ''
      },
      rectifyList: [],
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
          this.getRectifyList()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/riskCheck/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 查询整改记录
    getRectifyList() {
      this.$http.get(
          '/opm/riskRectify/list',
          {
            params: {
              mainId: this.dataForm.id
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.rectifyList = res.data
        this.rectifyList.forEach((obj) => {
          switch (obj.statusId) {
            case 1:
              obj.color = 'orange'
              obj.statusContent = '整改中'
              break
            case 2:
              obj.color = 'green'
              obj.statusContent = '整改完成'
              break
          }
        })
      }).catch(() => {
        //  do nothing
      })
    }
  }
}
</script>
